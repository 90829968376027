.Answer {
  margin: 0 20px;

  p {
    font-size: 20px;
    font-weight: 600;
  }
}

.OptionList {
  list-style: none;
  margin-left: 0;
  margin-bottom: 20px;
  padding-left: 0;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;

  li {
    margin-bottom: 20px;
  }
}

.answerSection {
  .question {
    margin: 1rem 0;
  }
}

.answerOptions {
  display: flex;
  overflow: scroll;
  white-space: nowrap;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  margin-right: 0.5rem;
  padding-bottom: 10px;

  .optionsBtn {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0;
    outline: 0;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &:visited {
      border: 0;
    }
  }

  &.alignCenter {
    justify-content: center;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
    padding: 0;
  }
}
