.Patient {
    max-width: 768px;
    background: linear-gradient(167.94deg, #D3E7E7 0.83%, #EEEBE1 27.53%, #F6DCCC 61.56%, #93DCF1 101.35%);
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.PatientAnswers {
    height: 100%;
    background-image: none;
    background-color: white;
}