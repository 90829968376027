.ErrorWrapper {
  max-width: 768px;
  background: linear-gradient(
    167.94deg,
    #d3e7e7 0.83%,
    #eeebe1 27.53%,
    #f6dccc 61.56%,
    #93dcf1 101.35%
  );
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .box {
    width: 80%;
    height: 350px;
    background:rgba(#fff, 0.6);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

  .ErrorIcon {
    width: 66px;
  }

  .ErrorMessage {
    text-align: center;
    margin-bottom: 0;
  }

  .ButtonSubmit {
    margin: 20px 0 0;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Source Sans Pro";
    min-width: 150px;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    background-color: #f25832;
    box-shadow: 0 0 20px #eee;
    border-radius: 50px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    &:hover {
      background-position: right center; /* change the direction of the change here */
    }
  }
}
