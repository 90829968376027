.PatientLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .submitBtn {
    margin: 20px 0 10px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Source Sans Pro";
    min-width: 150px;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    background-color: #f25832;
    box-shadow: 0 0 20px #eee;
    border-radius: 50px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    &:hover {
      background-position: right center; /* change the direction of the change here */
    }
    &:disabled {
      background-image: none;
      color: #666060;
      background-color: lightgrey;
    }
  }
}
