.PatientAnswers {
  background: linear-gradient(
    167.94deg,
    #d3e7e7 0.83%,
    #eeebe1 27.53%,
    #f6dccc 61.56%,
    #93dcf1 101.35%
  );
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.HeadingText {
  font-size: 24px;
  padding-bottom: 0;
  font-weight: 600;
  color: #383838;
}

.ButtonWrapper {
  text-align: center;
  margin-top: auto;
}

.ButtonSubmit {
  margin: 10px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Source Sans Pro";
  min-width: 150px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  background-color: #f25832;
  box-shadow: 0 0 20px #eee;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  &:hover {
    background-position: right center; /* change the direction of the change here */
  }
  &:disabled {
    background-image: none;
    color: #666060;
    background-color: lightgrey;
  }
}

.CircularLoader{
  margin: auto;
}