.PatientSuccess {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .box {
        width: 80%;
        height: 350px;
        background:rgba(#fff, 0.6);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        padding: 20px;
        text-align: center;
    }
}