.PlayerWrapper {
  position: relative;
}

.ReactPlayer {
  video {
    object-fit: cover;
    height: 47vh !important;
  }
}
